import { isEmptyArray } from "@/utils";
import { FILENAME_INDEX, SELECT_FOR_UPLOAD_INDEX } from "camera/lib/Recorder/DB";
import Uploader from "./Uploader";
import { getIsPaired } from "camera/store/station/selectors";
import { useStation } from "camera/store/station";
import CrashReporter from "@/modules/app/CrashReporter";

type HlsDataItems = (HlsDataItem & { primaryKey: number })[];

export class MediaUploadManager {
  private static recorder: HlsRecorder | null;
  private static isPreparingSegments = false;
  private static hasNewerData = false;
  private static isUploading = false;

  private static segmentsToUpload: HlsDataItems = [];
  private static currentlyUploadingKeys: { [key: string]: boolean } = {};
  private static uploadedInitFilesMap = new Map<string, boolean>();

  private static getIsPaired = () => getIsPaired(useStation.getState());

  static reset = () => {
    this.isPreparingSegments = false;
    this.hasNewerData = false;
    this.isUploading = false;

    this.segmentsToUpload = [];
    this.currentlyUploadingKeys = {};
    this.uploadedInitFilesMap.clear();
  };

  static init = (recorderCreator: RecorderCreator) => {
    this.recorder = recorderCreator.recorder;
    this.recorder.on("segments-should-be-uploaded", this.prepareSegmentsForUpload);

    const unsub = recorderCreator.onRecorderInit(() => {
      unsub();
      if (this.getIsPaired()) this.prepareSegmentsForUpload();
    });
  };

  static getUnuploadedSegmentCount = () => this.segmentsToUpload.length;

  private static uploadInitFileIfNotUploaded = async (segment: HlsDataItem) => {
    if (!this.recorder) return;
    const { initFilename } = segment;

    if (!initFilename) throw Error(`'initFilename' not found on segment ${segment.filename}`);
    if (this.uploadedInitFilesMap.has(initFilename)) return;

    const initFile = await this.recorder.db.getRead<HlsDataItem>("hls-data", "filename")(initFilename);
    if (!initFile) throw Error(`Init file for ${initFilename} was not found for file ${segment.filename}`);

    if (initFile.uploaded === 1) log.warn(`init file ${initFile.filename} already uploaded, uploading again`);
    log.upload("About to upload init", initFile.filename);
    await this.uploadInit(initFile);

    const keyRange = IDBKeyRange.only(initFilename);
    const objectStore = this.recorder.db.createTransaction("readwrite", "hls-data");
    const index = objectStore.index(FILENAME_INDEX);
    const request = index.openCursor(keyRange);

    request.onsuccess = async (e) => {
      const cursor = (e.target as IDBRequest<IDBCursorWithValue>).result;
      if (cursor) {
        const file = cursor.value as HlsDataItem;
        file.uploaded = 1;
        log.upload("About to update init file in DB", file);
        cursor.update(file);
      }
    };
    this.uploadedInitFilesMap.set(initFilename, true);
  };

  private static upload = () => {
    if (this.isUploading) return;
    this.isUploading = true;

    const next = async () => {
      try {
        const toUpload = [...this.segmentsToUpload];
        for (const segment of toUpload) {
          if (!this.recorder) return;
          await this.uploadInitFileIfNotUploaded(segment);
          log.upload("About to upload segment", segment.filename);
          await this.uploadSegment(segment);

          segment.uploaded = 1;
          segment.selectForUpload = 0;
          const { primaryKey, ...updatedSegment } = segment;

          await this.recorder.db.getWrite<HlsDataItem>("hls-data")(updatedSegment, segment.primaryKey);
          this.segmentsToUpload.shift();
          this.removeFromUploadingKeys(segment.createdAt);
        }
        if (isEmptyArray(this.segmentsToUpload)) this.isUploading = false;
        else next();
      } catch (err: any) {
        log.err("Encoutered client error while uploading", err);
        CrashReporter.sendException("upload' failed", { key: "err", extra: err?.message || err });
        if (!isDev) this.segmentsToUpload.shift();
      }
    };

    next();
  };

  private static prepareSegmentsForUpload = async (force?: boolean) => {
    if (!this.recorder || (this.isPreparingSegments && !force)) return;
    log.upload("'prepareSegmentsForUpload' called");

    this.isPreparingSegments = true;

    const keyRange = IDBKeyRange.only(1);
    const objectStore = this.recorder.db.createTransaction("readwrite", "hls-data");
    const index = objectStore.index(SELECT_FOR_UPLOAD_INDEX);
    const request = index.openCursor(keyRange, "next");

    const segmentsToUpload: HlsDataItems = [];
    request.onsuccess = async (e) => {
      try {
        const cursor = (e.target as IDBRequest<IDBCursorWithValue>).result;
        if (cursor) {
          const hlsData = cursor.value as HlsDataItem;
          if (hlsData.uploaded !== 1) {
            log.upload("Found unuploaded segment", hlsData.filename);
            segmentsToUpload.push({
              ...hlsData,
              primaryKey: cursor.primaryKey as number
            });
          }
          cursor.continue();
        } else {
          log.upload("Prepared all segments");
          this.addUniqueItemsToUploadQueue(segmentsToUpload);
          if (this.hasNewerData) {
            this.hasNewerData = false;
            this.prepareSegmentsForUpload(true);
          } else {
            this.isPreparingSegments = false;
            if (!this.isUploading) this.upload();
          }
        }
      } catch (err) {
        this.isPreparingSegments = false;
        log.err("Error while preparing segments", err);
      }
    };
    request.onerror = (e) => {
      this.isPreparingSegments = false;
      log.err("'upload' failed while reading DB", e);
    };
  };

  static startMarkingDataForUpload = () => {
    if (!this.recorder) return;
    log.upload("Called 'startMarkingDataForUpload'");

    this.recorder.startMarkingDataForUpload();
    if (this.isPreparingSegments) this.hasNewerData = true;
  };

  static stopMarkingDataForUpload = () => {
    if (!this.recorder) return;
    log.upload("Called 'stopMarkingDataForUpload'");
    this.recorder.stopMarkingDataForUpload();
  };

  private static uploadInit = async (file: HlsDataItem) => {
    if (!file.initId) {
      log.err("Couldnt find ID of init file", file.filename);
      return;
    }
    const blob = new Blob([file.data], { type: "video/mp2t" });
    const payload = new FormData();

    payload.append("contentType", "VIDEO");
    payload.append("type", "INIT");
    payload.append("initId", String(file.initId));
    payload.append("imageRotation", "ROTATION_0");
    payload.append("file", blob, file.filename);

    await Uploader.upload(payload, file.filename);
  };

  private static uploadSegment = async (file: HlsDataItem) => {
    if (!this.recorder) return;

    if (!file.initId) {
      log.err("Couldnt find ID of init file", file.initFilename);
      return;
    }

    const blob = new Blob([file.data], { type: "video/mp2t" });
    const payload = new FormData();

    payload.append("contentType", "VIDEO");
    payload.append("type", "DATA");
    payload.append("initId", String(file.initId));
    payload.append("discontinuity", String(file.discontinuity));
    payload.append("date", String(new Date(file.createdAt).getTime()));
    payload.append("duration", String(Math.round(parseFloat(file.duration!) * 1000)));
    payload.append("file", blob, file.filename);

    const response = await Uploader.upload(payload, file.filename);
    if (response && response !== "OK") {
      log.err(`Response for ${file.filename} upload was not OK`, response);
      if (response === "INIT_SEGMENT_NOT_FOUND") {
        if (file.initFilename) this.uploadedInitFilesMap.delete(file.initFilename);
        await this.uploadInitFileIfNotUploaded(file);
      }
    }
  };

  private static addUniqueItemsToUploadQueue = (itemsToUpload: HlsDataItems) => {
    itemsToUpload.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());

    itemsToUpload.forEach((item) => {
      const alreadyExists = this.addToUploadingKeysIfDoesntExist(item.createdAt);
      if (alreadyExists) return;
      this.segmentsToUpload.push(item);
    });
  };

  private static addToUploadingKeysIfDoesntExist = (key: string) => {
    if (this.currentlyUploadingKeys[key]) return true;
    this.currentlyUploadingKeys[key] = true;
    return false;
  };

  private static removeFromUploadingKeys = (key: string) => {
    delete this.currentlyUploadingKeys[key];
  };
}
