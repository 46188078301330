import environment from "@/modules/environment";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { addTimeout } from "@/utils";
import CrashReporter from "@/modules/app/CrashReporter";
import { TRANSCODER_RESET_TIME } from "../const";
import { decoder, msToFixedSeconds } from "../extensions";

type Payload = {
  blob: Blob;
  includeInitData?: boolean;
  appendInMs: number | null;
};

export default class Transcoder {
  constructor(private playlist: Playlist) {}
  private ffmpeg!: FFmpeg;
  private initDate = new Date();
  private path = isDev ? window.location.origin : window.location.origin + environment.publicPath;
  private errorCount = 0;

  private create = async () => {
    if (!this.ffmpeg) this.ffmpeg = new FFmpeg();
    else this.ffmpeg.terminate();

    await this.ffmpeg.load({
      coreURL: `${this.path}/ffmpeg/ffmpeg-core.js`,
      wasmURL: `${this.path}/ffmpeg/ffmpeg-core.wasm`
    });
  };

  init = async () => {
    if (!this.ffmpeg) await this.create();
    if (!this.ffmpeg.loaded) {
      log.recorderTrancsoder("Loading ffmpeg");
      await this.ffmpeg.load();
      log.recorderTrancsoder("Fffmpeg loaded");
    }
  };

  private sortSegmentFiles = (a: string, b: string) => {
    const segmentNumberA = parseInt(a.match(/\d+/)![0]);
    const segmentNumberB = parseInt(b.match(/\d+/)![0]);
    return segmentNumberA - segmentNumberB;
  };

  private refreshInstance = async (force?: boolean) => {
    const now = new Date().getTime();
    if (this.initDate.getTime() + TRANSCODER_RESET_TIME < now || force) {
      log.recorderTrancsoder("Creating new FFMPEG instance");

      await this.create();
      this.initDate = new Date();
    }
  };

  private _transcode = async ({ blob, includeInitData, appendInMs }: Payload) => {
    log.recorderTrancsoder("'_transcode' called");
    if (!this.ffmpeg) log.err("Ffmpeg not initialized");

    const arrayBuffer = await blob.arrayBuffer();
    await this.ffmpeg.writeFile("input.webm", new Uint8Array(arrayBuffer));

    const toMp4Options = ["-i", "input.webm", "-c:a", "aac", "-c:v", "copy", "input.mp4"];
    await this.ffmpeg.exec(toMp4Options);

    if (appendInMs) {
      const listFile = "file 'short.mp4'\nfile 'input.mp4'";
      const listFileBuffer = await new Blob([listFile], {
        type: "plain/text"
      }).arrayBuffer();
      const appendMp4Options = [
        "-i",
        "input.mp4",
        "-ss",
        "00:00:00",
        "-t",
        `00:00:${msToFixedSeconds(appendInMs)}`,
        "-c",
        "copy",
        "short.mp4"
      ];
      const combineOptions = ["-f", "concat", "-safe", "0", "-i", "list.txt", "-c", "copy", "combined.mp4"];

      await this.ffmpeg.exec(appendMp4Options);
      await this.ffmpeg.writeFile("list.txt", new Uint8Array(listFileBuffer));
      await this.ffmpeg.exec(combineOptions);
    }

    const toPlaylistOptions = [
      "-i",
      appendInMs ? "combined.mp4" : "input.mp4",
      "-c:a",
      "aac",
      "-c:v",
      "copy",
      "-hls_time",
      "2",
      "-hls_list_size",
      "0",
      "-hls_flags",
      "omit_endlist",
      "-hls_segment_type",
      "fmp4",
      "-hls_segment_filename",
      "segment%01d.m4s",
      "-hls_fmp4_init_filename",
      "init.mp4",
      "playlist.m3u8"
    ];

    await this.ffmpeg.exec(toPlaylistOptions);

    const ffmpegFilenames = (await this.ffmpeg.listDir("/")).map((fsnode) => fsnode.name);
    const playlistData = (await this.ffmpeg.readFile("playlist.m3u8")) as Uint8Array;

    const segmentFilenames = ffmpegFilenames.filter((file) => file.includes("segment")).sort(this.sortSegmentFiles);

    const segmentDurations = this.playlist.getDurationsForSegmentsFromPlaylist(decoder.decode(playlistData));

    const segmentsData = await Promise.all(
      segmentFilenames.map(async (filename, index) => {
        const data = (await this.ffmpeg.readFile(filename)) as Uint8Array;
        await this.ffmpeg.deleteFile(filename);

        return {
          data,
          duration: segmentDurations[index]
        };
      })
    );

    const initData = includeInitData ? ((await this.ffmpeg.readFile("init.mp4")) as Uint8Array) : null;

    const appendFilenames = appendInMs ? ["short.mp4", "combined.mp4", "list.txt"] : [];
    await Promise.all(
      ["input.webm", "input.mp4", "playlist.m3u8", "init.mp4", ...appendFilenames].map(
        async (filename) => await this.ffmpeg.deleteFile(filename)
      )
    );

    await this.refreshInstance();
    return {
      segmentsData,
      initData
    };
  };

  transcode = async (payload: Payload): ReturnType<typeof this._transcode> => {
    try {
      const result = await addTimeout(() => this._transcode(payload), 15000);
      this.errorCount = 0;
      return result;
    } catch (err) {
      if (this.errorCount < 3) {
        this.errorCount++;
        CrashReporter.sendMessage((err as Error)?.message || (err as string));
        log.err("Transcoding failed, trying again...", err);
        await this.refreshInstance(true);
        log.recorderTrancsoder("Instance refreshed successfully");
        return await this.transcode(payload);
      } else {
        this.errorCount = 0;
        throw Error("Transcoding failed too many times");
      }
    }
  };
}
