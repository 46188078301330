import type { PartsStyleFunction, SystemStyleFunction } from "@chakra-ui/theme-tools";

const baseStyleControl: SystemStyleFunction = (props) => {
  return {
    w: "24",
    h: "24",
    transitionProperty: "box-shadow",
    transitionDuration: "normal",
    border: "2px solid",
    borderColor: "on-surface-variant",
    borderRadius: "100%",
    transition: "border 0.2s ease-in-out",
    _checked: {
      border: "6px solid",
      borderColor: "primary"
    },
    _disabled: {
      opacity: 0.4
    }
  };
};

const baseStyle: PartsStyleFunction = (props) => ({
  label: {
    userSelect: "none",
    _disabled: { opacity: 0.4 },
    ml: "10"
  },
  container: { _disabled: { cursor: "not-allowed" } },
  control: baseStyleControl(props)
});

export default {
  baseStyle
};
