import { wait } from "@/utils";
import { PendingEventsStore, usePendingEvents } from "camera/store/pendingEvents";
import { createCameraHistoryEvent } from "../cloud/media";
import LocalEvents from "../LocalEvents";

const RETRY_TIMEOUT = 3000;
const FAILURE_THRESHOLD = 3;
const { getOldestEvent, removeEvent, moveEventToTheEnd } = usePendingEvents.getState();

export default class EventUploader {
  static state: "uploading" | "idle" = "idle";
  private static unsubscribe: Cb;
  private static failedAttempt = 0;

  private static closeAndAddUnfinishedEventsToQueue = async () => {
    try {
      const closedEvents = await Promise.all(
        usePendingEvents.getState().unfinishedEvents.map(async (e) => {
          e.finished = true;
          log.events("Closing unfinished event", e);
          await LocalEvents.save(e);
          return e;
        })
      );
      usePendingEvents.setState(({ events }) => ({
        unfinishedEvents: [],
        events: [...closedEvents, ...events]
      }));
    } catch (err) {
      log.err("Encountered error in 'closeAndAddUnfinishedEventsToQueue'");
      log.err(err);
    }
  };

  static start = async () => {
    await this.closeAndAddUnfinishedEventsToQueue();
    this.unsubscribe = usePendingEvents.subscribe(this.onStoreUpdate);
    if (usePendingEvents.getState().events.length > 0) this.upload();
  };

  private static onStoreUpdate = (store: PendingEventsStore) => {
    if (store.events.length > 0 && this.state !== "uploading") this.upload();
  };

  private static upload = async () => {
    const event = getOldestEvent();
    if (!event) {
      log.events("Finished uploading all events");
      this.state = "idle";
      return;
    }
    try {
      this.state = "uploading";
      log.events("About to upload event:", event);
      const res = await createCameraHistoryEvent({ cameraEvent: event });
      if (!res) throw Error("Failed 'createCameraHistoryEvent'");
      log.events("Uploaded event", event.uniqueId);

      this.failedAttempt = 0;
      const eventsInStore = removeEvent(event.storeId);
      if (eventsInStore.length > 0) this.upload();
      else this.state = "idle";
    } catch (err) {
      log.events("Failed event upload for event", event, err);
      this.failedAttempt += 1;

      if (this.failedAttempt >= FAILURE_THRESHOLD) {
        this.failedAttempt = 0;
        moveEventToTheEnd(event.storeId);
        this.upload();
      } else {
        await wait(RETRY_TIMEOUT);
        this.upload();
      }
    }
  };

  static destroy = () => {
    this.unsubscribe();
  };
}
