import { useDevices } from "@/store/devices";
import PresenceKeeper from "../app/Presence";
import { messages } from "../communication";
import Messenger from "../Messenger";

export default class Announcer {
  private static instance: Announcer;
  private messenger = Messenger.getInstance();

  static getInstance() {
    if (!Announcer.instance) {
      Announcer.instance = new Announcer();
      log.announcer("Announcer initialized");
    }
    return Announcer.instance;
  }

  announceItemChange = async (item: CloudAccountSyncItem) => {
    const devices = useDevices.getState().devices;
    const availableDevices = PresenceKeeper.getAvailableJids(devices);
    log.announcer("About to announce change", item);

    const message = messages.CloudAccountSyncMessage.create({
      item,
    });
    availableDevices.forEach((jid) => {
      this.messenger.sendAsEnvelope({ to: jid, payload: { message } });
    });
  };

  announceChange = () => this.announceItemChange("ALL");
}
