import { Flex, Heading, Button, Text, Box } from "@chakra-ui/react";
import Circle from "@/components/Circle";
import NavigationBar from "@/components/NavigationBar";
import { t, openEmailClient } from "@/utils";

export default function HowToPair({ push, close }: FlowProps) {
  return (
    <Flex grow={1} direction="column" alignItems="center">
      <NavigationBar props={{ my: "1rem" }} onClose={() => close()} />
      <Flex
        h="100%"
        w="100%"
        px="2.5rem"
        direction="column"
        align="center"
        rowGap="1.5rem"
        justifyContent="center"
        flexGrow={1}
      >
        <Heading as="h4" mb="0.5rem">
          {t("pairing.camera.guide.title")}
        </Heading>
        <Flex columnGap="12px" alignSelf="flex-start">
          <Circle size="28" bg="primary" props={{ color: "on-primary" }}>
            1
          </Circle>
          <Box>
            <Text>{t("pairing.add.camera.step1")}</Text>
            <Text
              as="button"
              color="primary"
              mt="0.5rem"
              onClick={() =>
                push("device-select", {
                  stepDirection: "next",
                  stateUpdate: {
                    isCamera: true,
                    previousStepId: "how-to-pair"
                  }
                })
              }
            >
              {t("pairing.help.install.title")}
            </Text>
          </Box>
        </Flex>
        <Flex columnGap="12px" alignSelf="flex-start" alignItems="center">
          <Circle size="28" bg="primary" props={{ color: "on-primary" }}>
            2
          </Circle>
          <Flex alignItems="center">
            <Text>
              {t("pairing.camera.guide.step2", {
                b: <strong />
              })}
            </Text>
          </Flex>
        </Flex>
        <Flex columnGap="12px" alignSelf="flex-start" alignItems="center">
          <Circle size="28" bg="primary" props={{ color: "on-primary" }}>
            3
          </Circle>
          <Flex alignItems="center">
            <Text>{t("pairing.camera.guide.step3")}</Text>
          </Flex>
        </Flex>
        <Flex columnGap="12px" alignSelf="flex-start" alignItems="center">
          <Circle size="28" bg="primary" props={{ color: "on-primary" }}>
            4
          </Circle>
          <Flex alignItems="center">
            <Text>{t("pairing.camera.guide.step4")}</Text>
          </Flex>
        </Flex>
      </Flex>
      <Button variant="text" mt="3rem" w="18.5rem" onClick={() => openEmailClient()}>
        {t("purchase.help.contactUs.heading")}
      </Button>
    </Flex>
  );
}
