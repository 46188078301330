import { useState, useRef, useCallback, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { dataSyncEmitter } from "@/modules/events/emitter";

const animateNext = {
  initial: { x: "25%", opacity: 0 },
  animate: { x: 0, opacity: 1 },
  exit: { x: "-25%", opacity: 0 }
};

const animatePrevious = {
  initial: { x: "-25%", opacity: 0 },
  animate: { x: 0, opacity: 1 },
  exit: { x: "25%", opacity: 0 }
};

const variants = {
  next: animateNext,
  previous: animatePrevious
} as const;

export default function Flow({ steps, initialStep, ...rest }: FlowProps) {
  const _initialStep = useRef(initialStep || steps[0].id).current;
  const [currentStep, setCurrentStep] = useState(_initialStep);
  const [direction, setDirection] = useState<Direction | "none">("none");
  const [isAnimating, setIsAnimating] = useState(false);
  const [history, setHistory] = useState([_initialStep]);
  const [state, setState] = useState({});

  const _steps = useRef(steps).current;
  const nextStepId = useRef<string | null>(null);
  const animationTimeout = useRef<null | NodeJS.Timeout>(null);

  const Step = _steps.find((step) => step.id === currentStep)?.Component;
  const animationVariant = variants[direction as Direction] as (typeof variants)[Direction] | undefined;

  const updateHistory = useCallback((update: string) => {
    setHistory((prev) => [update, ...prev]);
  }, []);
  const updateState = useCallback((update: Keyable = {}) => {
    setState((prev) => ({ ...prev, ...update }));
  }, []);
  const updateStateAndHistory = useCallback(
    ({ stateUpdate, stepId }: { stateUpdate?: Keyable; stepId: string }) => {
      updateState(stateUpdate);
      updateHistory(stepId);
    },
    [updateHistory, updateState]
  );

  const announceTransitionAnimation = useCallback(() => {
    if (animationTimeout.current) {
      clearTimeout(animationTimeout.current);
      animationTimeout.current = null;
    } else dataSyncEmitter.emit("flow-transition-start");

    animationTimeout.current = setTimeout(() => {
      animationTimeout.current = null;
      dataSyncEmitter.emit("flow-transition-end");
    }, 500);
  }, []);

  const move = useCallback(
    ({ stepId, stepDirection, stateUpdate }: FlowMove) => {
      updateStateAndHistory({ stepId, stateUpdate });
      nextStepId.current = stepId;
      setDirection(stepDirection);
      announceTransitionAnimation();
      setIsAnimating(true);
    },
    [updateStateAndHistory, announceTransitionAnimation]
  );

  const push = useCallback(
    (stepId: typeof currentStep, { stepDirection, stateUpdate }: FlowPush = { stepDirection: "next" }) => {
      move({ stepId, stepDirection: stepDirection || "next", stateUpdate });
    },
    [move]
  );

  const next = useCallback(
    ({ stateUpdate }: FlowNext = {}) => {
      const currentIndex = _steps.findIndex((step) => step.id === currentStep);
      const nextStep = _steps[currentIndex + 1];
      if (nextStep) {
        move({ stepId: nextStep.id, stepDirection: "next", stateUpdate });
      }
    },
    [_steps, currentStep, move]
  );

  const previous = useCallback(
    ({ stateUpdate }: FlowPrevious = {}) => {
      const currentIndex = _steps.findIndex((step) => step.id === currentStep);
      const _previousStepId = history[1];
      const previousStepFallback = _steps[currentIndex - 1];
      const previousStepId = _previousStepId || previousStepFallback?.id;

      if (previousStepId) {
        move({
          stepId: previousStepId,
          stepDirection: "previous",
          stateUpdate
        });
      }
    },
    [_steps, history, currentStep, move]
  );

  useEffect(() => {
    if (isAnimating && nextStepId.current) {
      setCurrentStep(nextStepId.current);
      setIsAnimating(false);
    }
  }, [isAnimating]);

  return (
    <AnimatePresence initial={false} mode="wait">
      <motion.div
        key={currentStep}
        variants={animationVariant}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.2, ease: "easeInOut" }}
        style={{ display: "flex", flexGrow: 1, flexDirection: "column" }}
      >
        {(Step && <Step push={push} next={next} previous={previous} history={history} state={state} {...rest} />) ||
          null}
      </motion.div>
    </AnimatePresence>
  );
}
