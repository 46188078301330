import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  ModalProps,
  ModalContentProps,
  ModalBodyProps,
  TooltipProps,
  Tooltip
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { dataSyncEmitter } from "@/modules/events/emitter";
import { useTooltipManager } from "@/lib/TooltipManager";

type Props = {
  title?: string | React.ReactNode;
  isOpen: boolean;
  onClose: Cb;
  props?: Omit<ModalProps, "onClose" | "isOpen" | "children">;
  footerContent?: React.ReactNode;
  closeButton?: boolean;
  children?: React.ReactNode;
  modalContentProps?: ModalContentProps;
  modalBodyProps?: ModalBodyProps;
  disableNavigationEvents?: boolean;
  tooltip?: Omit<TooltipProps, "children">;
};

const modalEscEvent = new Event("modal-esc");

export default function Modal({
  title,
  isOpen,
  onClose,
  footerContent,
  closeButton,
  props,
  children,
  modalContentProps,
  modalBodyProps,
  tooltip,
  // HOTFIXED situation where two modals are opened and one of them has NavigationBar
  disableNavigationEvents
}: Props) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [bodyStyles, setBodyStyles] = useState<ModalBodyProps>({ overflow: "auto" });
  const { addTooltip, removeTooltip, tooltipDelay } = useTooltipManager();

  useEffect(() => {
    const unsub = [
      dataSyncEmitter.on("flow-transition-start", () => setBodyStyles({ overflow: "hidden" })),
      dataSyncEmitter.on("flow-transition-end", () => setBodyStyles({ overflow: "auto" }))
    ];
    return () => unsub.forEach((cb) => cb());
  }, []);

  return (
    <ChakraModal
      isOpen={isOpen}
      onClose={onClose}
      size="2xl"
      isCentered
      scrollBehavior="inside"
      onEsc={() => !disableNavigationEvents && document.dispatchEvent(modalEscEvent)}
      initialFocusRef={containerRef}
      {...props}
    >
      <ModalOverlay />
      <ModalContent minH={484} {...modalContentProps} ref={containerRef}>
        {closeButton && (
          <Tooltip
            onOpen={addTooltip}
            {...(tooltip
              ? {
                  gutter: 4,
                  openDelay: tooltipDelay,
                  ...tooltip
                }
              : { isDisabled: true })}
          >
            <ModalCloseButton onMouseLeave={removeTooltip} top="16" right="16" zIndex={10} />
          </Tooltip>
        )}
        {title && <ModalHeader>{title}</ModalHeader>}
        {/* eslint-disable-next-line react/prop-types */}
        <ModalBody {...bodyStyles} {...modalBodyProps} allow-navigation-events={String(!props?.closeOnEsc !== false)}>
          {children}
        </ModalBody>
        {footerContent && <ModalFooter>{footerContent}</ModalFooter>}
      </ModalContent>
    </ChakraModal>
  );
}
