import { isEmptyArray } from "@/utils";
import { MAXIMUM_GAP_DURATION } from "../const";
import { DATE_INDEX, HlsDb } from "../DB";
import { isGapFile, isInitFile, isSegmentFile } from "../extensions";

export default class DbController {
  getRead: HlsDb["getRead"];
  readAllFiles: HlsDb["readAll"];

  constructor(private db: HlsDb) {
    this.db = db;
    this.getRead = this.db.getRead;
    this.readAllFiles = this.db.readAll;
  }

  getMetaCursor = (mode: IDBTransactionMode = "readonly", cursorOptions: Parameters<IDBObjectStore["openCursor"]>) => {
    const index = this.db.createTransaction(mode, "hls-meta");
    const request = index.openCursor(...cursorOptions);
    return request;
  };

  private deleteRemainingFiles = async ({
    dataFilesToDelete,
    metaFilesToDelete
  }: {
    dataFilesToDelete: string[];
    metaFilesToDelete: string[];
  }) => {
    const metaRemove = this.db.getDelete("hls-meta", "filename");
    const dataRemove = this.db.getDelete("hls-data", "filename");
    log.recorderDbcontroller("About to delete meta files: ", metaFilesToDelete);
    log.recorderDbcontroller("About to delete data files: ", dataFilesToDelete);
    metaFilesToDelete.forEach((filename) => metaRemove(filename));
    dataFilesToDelete.forEach((filename) => dataRemove(filename));
  };

  deleteOlderThan = async (time: number, onDiscontinuityDelete?: () => void) => {
    log.recorderDbcontroller("'deleteOlderThan' called with time", time);
    const upperBound = new Date(Date.now() - time).toISOString();
    const range = IDBKeyRange.upperBound(upperBound);

    const metaIndex = this.db.createTransaction("readwrite", "hls-meta").index(DATE_INDEX);
    const metaRequest = metaIndex.openCursor(range);

    const dataIndex = this.db.createTransaction("readwrite", "hls-data").index(DATE_INDEX);
    const dataRequest = dataIndex.openCursor(range);

    let initMetaFilenamesToDelete: string[] = [];
    let initDataFilenamesToDelete: string[] = [];
    metaRequest.onerror = (e) => log.recorderDbcontroller("'deleteOlderThan' failed", e);
    dataRequest.onerror = (e) => log.recorderDbcontroller("'deleteOlderThan' failed", e);

    await Promise.all([
      new Promise<void>((resolve) => {
        metaRequest.onsuccess = (e) => {
          const cursor = (e.target as IDBRequest<IDBCursorWithValue>).result;
          if (cursor) {
            const file = cursor.value as HlsMetaItem;
            const isInit = isInitFile(file);

            if (isInit) {
              initMetaFilenamesToDelete.push(file.filename);
            } else {
              log.recorderDbcontroller("Deleting meta file", file.filename);
              if (file.discontinuity && onDiscontinuityDelete) onDiscontinuityDelete();
              cursor.delete();
            }
            cursor.continue();
          } else resolve();
        };
      }),
      new Promise<void>((resolve) => {
        dataRequest.onsuccess = (e) => {
          const cursor = (e.target as IDBRequest<IDBCursorWithValue>).result;
          if (cursor) {
            const file = cursor.value as HlsDataItem;
            const isInit = isInitFile(file);

            if (isInit) {
              initDataFilenamesToDelete.push(file.filename);
            } else {
              if (file.shouldUpload === 1 && file.uploaded === 0 && file.initFilename) {
                initDataFilenamesToDelete = initDataFilenamesToDelete.filter((f) => f !== file.filename);
              } else {
                log.recorderDbcontroller("Deleting data file", file.filename);
                cursor.delete();
              }
            }
            cursor.continue();
          } else resolve();
        };
      })
    ]);

    return new Promise((resolve) => {
      const finish = async () => {
        const initFilenameToPreserve = await this.getRemainingInitFilename();
        log.recorderDbcontroller("Preserved init filename: ", initFilenameToPreserve);

        initDataFilenamesToDelete = initDataFilenamesToDelete.filter((f) => f !== initFilenameToPreserve);
        initMetaFilenamesToDelete = initMetaFilenamesToDelete.filter((f) => f !== initFilenameToPreserve);

        await this.deleteRemainingFiles({
          dataFilesToDelete: initDataFilenamesToDelete,
          metaFilesToDelete: initMetaFilenamesToDelete
        });
        resolve(1);
      };

      finish();
    });
  };

  fillSegmentGaps = async (date: string) => {
    const oldestSegment = await this.getFirstFoundSegmentFile("prev");
    if (!oldestSegment) {
      log.recorderDbcontroller("Not adding any gaps");
      return;
    }

    const { createdAt, duration } = oldestSegment;
    const gapStartTime = new Date(createdAt).getTime() + Number(duration) * 1000;
    const gapEndTime = new Date(date).getTime();

    const timeDifference = gapEndTime - gapStartTime;

    let seconds = timeDifference / 1000;
    const gaps: number[] = [];

    while (seconds >= MAXIMUM_GAP_DURATION) {
      gaps.push(MAXIMUM_GAP_DURATION);
      seconds -= MAXIMUM_GAP_DURATION;
    }
    if (seconds > 0.02) gaps.push(seconds);

    if (isEmptyArray(gaps)) return;

    const { initIndex, segmentIndex: startSegmentIndex } = await this.getNextUsableIndexes();

    const startDate = new Date(gapStartTime);
    let initFilename = `g${initIndex}.mp4`;
    const initPayload: HlsMetaGapItem = {
      filename: initFilename,
      initFilename: null,
      index: initIndex,
      createdAt: startDate.toISOString(),
      discontinuity: false,
      duration: null
    };

    const write = this.db.getWrite("hls-meta");
    await write(initPayload);

    let segmentIndex = startSegmentIndex;
    const segmentDate = startDate;

    log.recorderDbcontroller("gaps: ", gaps);
    const unevenGapInitIndex = initIndex + 1;

    for (const [index, gapDuration] of gaps.entries()) {
      const isLast = index === gaps.length - 1;

      if (isLast) {
        initFilename = `g${unevenGapInitIndex}.mp4`;
        const initPayload: HlsMetaGapItem = {
          filename: initFilename,
          initFilename: null,
          index: unevenGapInitIndex,
          createdAt: new Date(segmentDate).toISOString(),
          discontinuity: false,
          duration: null
        };
        await write(initPayload);
      }

      const payload: HlsMetaGapItem = {
        filename: `g${segmentIndex}.m4s`,
        initFilename,
        index: segmentIndex,
        createdAt: new Date(segmentDate).toISOString(),
        discontinuity: true,
        duration: gapDuration.toFixed(6)
      };
      await write(payload);

      segmentDate.setMilliseconds(segmentDate.getMilliseconds() + gapDuration * 1000);
      segmentIndex++;
    }
  };

  getFirstFoundSegmentFile = (direction: IDBCursorDirection) => {
    const request = this.getMetaCursor("readonly", [null, direction]);

    return new Promise<HlsMetaItem | null>((resolve) => {
      request.onsuccess = (e) => {
        const cursor = (e.target as IDBRequest<IDBCursorWithValue>).result;
        if (cursor) {
          const file = cursor.value as HlsMetaItem;
          if (isSegmentFile(file)) resolve(file);
          else cursor.continue();
        } else resolve(null);
      };
    });
  };

  private getRemainingInitFilename = () => {
    const request = this.getMetaCursor("readonly", [null, "next"]);

    return new Promise<string | null>((resolve) => {
      const findInitInDataFiles = async (segmentFilename: string) => {
        const file = await this.getRead<HlsDataItem>("hls-data", "filename")(segmentFilename);
        resolve(file?.initFilename || null);
      };

      request.onsuccess = (e) => {
        const cursor = (e.target as IDBRequest<IDBCursorWithValue>).result;
        if (cursor) {
          const file = cursor.value as HlsMetaItem | HlsMetaGapItem;
          if (isSegmentFile(file)) {
            if (isGapFile(file)) resolve(file.initFilename);
            else findInitInDataFiles(file.filename);
          } else cursor.continue();
        } else resolve(null);
      };
    });
  };

  getNextUsableIndexes = () => {
    const request = this.getMetaCursor("readonly", [null, "prev"]);

    let segmentIndex: number;
    let initIndex: number;

    return new Promise<{ segmentIndex: number; initIndex: number }>((resolve, reject) => {
      request.onsuccess = (e) => {
        const cursor = (e.target as IDBRequest<IDBCursorWithValue>).result;
        if (cursor) {
          const file = cursor.value as HlsMetaItem;
          if (!segmentIndex && isSegmentFile(file)) {
            segmentIndex = file.index + 1;
          }
          if (!initIndex && isInitFile(file)) {
            initIndex = file.index + 1;
          }

          if (segmentIndex != null && initIndex != null) {
            log.recorderDbcontroller("Found both indexes ", initIndex, "and", segmentIndex);
            resolve({ segmentIndex, initIndex });
          } else {
            cursor.continue();
          }
        } else {
          log.recorderDbcontroller("Cursor finished");
          resolve({
            segmentIndex: segmentIndex || 0,
            initIndex: initIndex || 0
          });
        }
      };
      request.onerror = (e) => {
        log.recorderDbcontroller("Cursor failed: ", e);
        reject("Cursor failed at 'getNextUsableIndexes'");
      };
    });
  };
}
